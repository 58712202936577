<template>
  <div class="main">
    <div class="content-box">
      <el-input
        maxlength="15"
        style="width: 240px"
        placeholder="按标题搜索"
        v-model="title"
      >
      </el-input>
      <!-- <el-input
        maxlength="100"
        style="width: 150px; margin-left: 10px"
        placeholder="按类型搜索"
        v-model="type"
      ></el-input> -->

      <el-button
        slot="suffix"
        @click="searchData"
        type="primary"
        icon="el-icon-search"
        style="border-radius: 0 4px 4px 0; margin-right: -5px"
        >搜索</el-button
      >

      <el-button
        @click="addHandler"
        type="primary"
        style="margin-left: 20px"
        icon="el-icon-plus"
        >新增</el-button
      >
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="80"
        ></el-table-column>

        <el-table-column
          prop="title"
          label="标题"
          align="center"
          min-width="220"
        ></el-table-column>

        <el-table-column
          prop="configKey"
          label="文章键名"
          align="center"
          min-width="180"
        ></el-table-column>

        <el-table-column
          prop="type"
          label="分类"
          align="center"
          min-width="180"
          :formatter="FormatType"
        ></el-table-column>

        <el-table-column
          prop="sort"
          label="排序"
          align="center"
          min-width="180"
        ></el-table-column>

        <!-- <el-table-column
          prop="content"
          label="内容"
          align="center"
          min-width="200"
        >
          <template slot-scope="scope">
           
            <el-tooltip :content="scope.row.content" placement="top">
              <div v-html="scope.row.content" style="max-height: 200px"></div>
            </el-tooltip>
          </template>
        </el-table-column> -->

        <!-- <el-table-column prop="status" label="状态" align="center" min-width="200"></el-table-column> -->
        <el-table-column
          prop="remark"
          label="备注"
          align="center"
          min-width="200"
        ></el-table-column>

        <el-table-column
          prop="status"
          label="状态"
          align="center"
          min-width="180"
          :formatter="FormatStatus"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-value="1"
              @change="changeStateHandler($event, scope.row)"
              inactive-value="0"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          min-width="130"
          :show-overflow-tooltip="true"
        ></el-table-column>

        <el-table-column label="操作" align="center" width="160">
          <template slot-scope="scope">
            <div class="editCell">
              <el-button type="primary" @click="editCardHandler(scope.row)"
                >编辑</el-button
              >
              <el-button type="danger" @click="deteteHandlet(scope.row)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      :title="isAdd ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <el-form ref="form" :model="formData" label-width="130px">
        <el-form-item class="nav-form-item" label="文章标题" prop="title">
          <el-input
            maxlength="256"
            v-model="formData.title"
            style="width: 300px"
            placeholder="请输入标题"
          />
        </el-form-item>

        <el-form-item class="nav-form-item" label="文章键名" prop="configKey">
          <el-input
            maxlength="256"
            v-model="formData.configKey"
            style="width: 300px"
            placeholder="请输入文章键名"
          />
        </el-form-item>

        <el-form-item class="nav-form-item" label="状态" prop="status">
          <el-select
            style="width: 200px"
            v-model="formData.status"
            placeholder="请选择"
          >
            <el-option label="开启" value="1"></el-option>
            <el-option label="关闭" value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="nav-form-item" label="分类" prop="type">
          <el-select
            style="width: 200px"
            v-model="formData.type"
            placeholder="请选择"
          >
            <el-option label="用户指南" value="0"></el-option>
            <el-option label="关于我们" value="1"></el-option>
            <el-option label="其他" value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="nav-form-item" label="排序" prop="sort">
          <el-input
            maxlength="256"
            v-model="formData.sort"
            style="width: 300px"
            placeholder="请输入排序"
          />
        </el-form-item>

        <!-- <el-input
            maxlength="10"
            v-model="formData.status"
            style="width: 300px"
            placeholder="请输入状态"
          /> -->

        <el-form-item class="nav-form-item" label="文章备注" prop="remark">
          <el-input
            maxlength="256"
            v-model="formData.remark"
            style="width: 300px"
            placeholder="请输入备注"
          />
        </el-form-item>

        <!-- <el-form-item label="文章内容" prop="content">
          <quill-editor
            v-model="formData.content"
            :options="editorOption"
            :min-height="250"
          />
        </el-form-item> -->

        <el-form-item label="文章内容" prop="content">
          <editor v-model="formData.content" :min-height="192" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="confimHandler">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getNewsList, delNews, addNews, editNews } from "@/api/news";
import { getCodesByParent } from "@/api/common";
// import { VueQuillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      imageUrl: "",
      dialogVisible: false,
      operatorId: "",
      date: "",
      isAdd: true,
      search: "",
      tableData: [],
      page: 1,
      pageSize: 10,
      loading: true,
      fileType: "",
      formData: {
        title: "",
        configKey: "",
        type: "",
        content: "",
        remark: "",
        status: "",
        sort: "",
      },
      total: 0,
      title: "",
      type: "",
      content: "",
      remark: "",
      status: "",
      pageData: [],

      editorOption: {
        placeholder: "请输入内容",
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ],

          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize"],
          },
        },
      },
    };
  },
  created() {
    this.getPages();
    this.getDataList();
  },
  methods: {
    handleUploadSuccess(res, file) {
      // this.formData.fileId = res.data.imgID
      this.imageUrl = URL.createObjectURL(file.raw);
      this.$refs.upload.clearFiles();
      (this.fileType = ""), (this.dialogVisible = false);
      this.getDataList();
    },
    searchData() {
      this.page = 1;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      let param = {
        title: this.title,
        type: this.type,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getNewsList(param).then((res) => {
        if (res.data.records) {
          this.tableData = [...res.data.records];
          this.total = res.data.total;
        }
        this.loading = false;
      });
    },
    getPages() {
      let param = {
        parentCode: 1,
      };
      getCodesByParent(param).then((res) => {
        console.log(res);
        if (res.data && res.data.length > 0) {
          this.pageData = [...res.data];
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDataList();
    },
    addHandler() {
      this.isAdd = true;
      this.formData.title = "";
      this.formData.type = "";
      this.formData.content = "";
      this.formData.remark = "";
      this.formData.status = "";
      this.formData.configKey = "";
      this.formData.sort = "";
      this.dialogVisible = true;
    },
    confimHandler() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.add();
          } else {
            this.editCard();
          }
        }
      });
    },
    editCardHandler(row) {
      this.isAdd = false;
      this.formData.id = row.id;
      this.formData.title = row.title;
      this.formData.type = row.type;
      this.formData.content = row.content;
      this.formData.remark = row.remark;
      this.formData.status = row.status;
      this.formData.configKey = row.configKey;
      this.formData.sort = row.sort;
      this.dialogVisible = true;
    },
    editCard() {
      var params = this.formData;
      params.showPage = null;
      params.showPageList = null;
      editNews(params).then((res) => {
        if (res.message.code == -1) {
          this.$message.error(res.data);
        } else {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        }
      });
    },
    add() {
      addNews(this.formData).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    deteteHandlet(row) {
      this.$confirm("确定删除所选项吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          id: row.id,
        };
        delNews(param).then((res) => {
          if (res.message.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getDataList();
          } else {
            this.$message.error(res.data);
          }
        });
      });
    },
    changeStateHandler($event, row) {
      let param = {
        status: $event,
        id: row.id,
      };
      editNews(param).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getDataList();
        } else {
          this.$message.error(res.data);
        }
      });
    },
    FormatStatus(row) {
      if (row.status == 1) {
        return "开启";
      } else if (row.status == 0) {
        return "关闭";
      }
    },
    FormatType(row) {
      if (row.type == 0) {
        return "用户指南";
      } else if (row.type == 1) {
        return "关于我们";
      } else if (row.type == 2) {
        return "其他";
      }
    },
  },
  // components: { VueQuillEditor },

  // components: {
  //   VueQuillEditor: VueQuillEditor.quillEditor,
  // },
};
</script>
<style lang="scss" scoped>
.title {
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.data {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 10px;
}

.img {
  width: 200px;
  height: 50px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
