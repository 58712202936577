<template>
    <div class="message-body">
        <div class="message-box" :style="{height: (warnList.length > 0 || platformList.length > 0) ? '':'100vh'}">
            <div class="left-box">
                <ul>
                    <li v-for="(item, index) in tabList" :key="index" :class="{ activeTab: currentKey === index }"
                        @click="handleChangeTab(index)"> <img :src="item.img" :style="{filter:currentKey === index ? 'drop-shadow(#057C98 0 0)':'' }"  alt=""> {{ item.tabName }}<el-badge :value="item.unReadCounts == 0 ? '' : item.unReadCounts" :max="99"
                            class="item"></el-badge></li>
                </ul>
            </div>
            <div class="right-box">
                <div class="header-tag">
                    <div>
                        <el-button-group>
                            <el-button class="sel-btn"
                                style="padding: 6px 12px;margin-right: 0; background: #fff !important;"
                                :style="{ borderColor: btnStyle ? '#057C98' : '', color: btnStyle ? '#057C98' : '' }"
                                @click="handleSelView(0)">全部</el-button>
                            <el-button class="sel-btn" style="padding: 6px 12px;background: #fff !important;"
                                :style="{ borderColor: !btnStyle ? '#057C98' : '', color: !btnStyle ? '#057C98' : '' }"
                                @click="handleSelView(1)">未读
                                <span v-if="currentKey === 0 && tabList[0].unReadCounts != 0">· {{
                                    tabList[0].unReadCounts }}</span>
                                <span v-if="currentKey === 1 && tabList[1].unReadCounts != 0">· {{
                                    tabList[1].unReadCounts }}</span>
                            </el-button>
                        </el-button-group>
                    </div>
                    <div class="right-flag" v-if="currentKey === 0 && tabList[0].unReadCounts != 0" @click="handleAllReady">
                        全部已读
                    </div>
                    <div class="right-flag" v-if="currentKey === 1 && tabList[1].unReadCounts != 0" @click="handleAllReady">
                        全部已读
                    </div>
                </div>
                <!-- 告警通知 -->
                <div class="message-container" v-show="currentKey === 0">
                    <div class="list" v-for="(item, index) in warnList" :key="index"
                        :class="{ activeList: currentListKey === index }" @click="currentListKey = index"
                        @mouseenter="hoveredIndex = index" @mouseleave="hoveredIndex = null" :style="{filter: item.isRead != 0 ? 'opacity(0.5)':'opacity(1)'}">
                        <div class="item-title">
                            <div>
                                <div class="is-dot"
                                    :style="{ background: item.isRead == 0 ? '#DF3737' : 'transparent' }">
                                </div>
                                {{ item.alertName }}
                                <div class="is-state1" v-if="item.level == 1">紧急</div>
                                <div class="is-state2" v-else-if="item.level == 2">严重</div>
                                <div class="is-state3" v-else>一般</div>
                            </div>
                            <div>
                                <div v-if="item.isRead == 0 && hoveredIndex === index" class="ready-stat"
                                    style="padding: 2px 8px;border: 1px solid red;border-radius: 4px;border: 1px solid #057C98;color: #057C98;background: #FFF;"
                                    @click.stop="handleNewsReadNews(item)">
                                    设为已读</div>
                                <div v-else class="ready-time">
                                    {{ item.createTime }}
                                </div>
                            </div>
                        </div>
                        <div class="item-desc">
                            <div>
                                <div style="margin-right: 12px;">充电站名称</div>
                                <div style="width: 180px; overflow: hidden; white-space: nowrap;text-overflow: ellipsis">{{ item.plotName }}</div>
                            </div>
                            <div style="width: 150px;">
                                <div style="margin-right: 12px;">设备类型</div>
                                <div v-show="item.deviceType == 1">充电桩</div>
                                <div v-show="item.deviceType == 2">充电枪</div>
                                <div v-show="item.deviceType == 3">电表</div>
                                <div v-show="item.deviceType == 4">其他</div>
                            </div>
                            <div style="width: 340px;display: flex;justify-content: flex-end;">
                                <div style="margin-right: 12px;">设备名称</div>
                                <div>{{ item.deviceName }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 平台消息 -->
                <div class="platform-container" v-show="currentKey === 1">
                    <div class="list" v-for="(item, index) in platformList" :key="index"
                        :class="{ activeList: currentListKey === index }" @click="currentListKey = index"
                        @mouseenter="hoveredIndex = index" @mouseleave="hoveredIndex = null" :style="{filter: (item.stat == 1 || item.stat == 2) ? 'opacity(0.5)':'opacity(1)'}">
                        <div class="item-title">
                            <div>
                                <div class="is-dot" :style="{ background: item.stat == 0 ? '#DF3737' : 'transparent' }">
                                </div> {{ item.title }}
                            </div>
                            <div>
                                <div v-if="item.stat == 0 && hoveredIndex === index" class="ready-stat"
                                    style="padding: 2px 8px;border: 1px solid red;border-radius: 4px;border: 1px solid #057C98;color: #057C98;background: #FFF;"
                                    @click.stop="handleNewsReadNews(item)">
                                    设为已读</div>
                                <div v-else class="ready-time">
                                    {{ item.publishTime }}
                                </div>
                            </div>
                        </div>

                        <div v-if="item.content && item.content != ''">
                            <div v-html="item.compiledHtml" style="padding-top: 8px;"></div>
                            <div class="image-gallery" style="margin-top: 8px;">
                                <img v-for="(image, index) in item.imgs"
                                    style="margin-right: 8px; width: 140px; height: 105px;border-radius: 4px;"
                                    :key="index" :src="image" class="image-gallery-item" @click="handlePreviewImages(item.imgs)">
                            </div>
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-show="currentKey === 0">
                    <div v-if="warnList.length > 0">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="paginationObj.page" :page-size="paginationObj.listPageSize"
                            layout="total, prev, pager, next,sizes, jumper"
                            :total="paginationObj.total"></el-pagination>
                    </div>
                    <el-empty v-else class="empty-block"
                        image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
                        :image-size="48"></el-empty>
                </div>
                <div v-show="currentKey === 1">
                    <div v-if="platformList.length > 0">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="paginationObj.page" :page-size="paginationObj.listPageSize"
                            layout="total, prev, pager, next,sizes, jumper"
                            :total="paginationObj.total"></el-pagination>
                    </div>
                    <el-empty v-else class="empty-block"
                        image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
                        :image-size="48"></el-empty>
                </div>
            </div>
        </div>
        <!-- 预览图片 -->
        <el-dialog :visible.sync="dialogVisibleImage" title="平台消息图片预览">
            <el-carousel class="image-swiper" :height="'100%'"
                :indicator-position="currentImages.length <= 1 ? 'none' : ''"
                :arrow="currentImages.length <= 1 ? 'never' : ''" :autoplay="false">
                <el-carousel-item v-for="(image, $i) in currentImages" :key="$i">
                    <el-image style="width: 100%; height: 100%" :src="image"
                        :preview-src-list="currentImages.map(v => v)" :initial-index="$i" :fit="'cover'">
                    </el-image>
                </el-carousel-item>
            </el-carousel>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleImage = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { Message } from "element-ui";

import { getName } from '@/utils/auth.js'
//为0代表不可操作，1可操作属于运营人员
const getIsoperator = JSON.parse(getName())

import {
    getUserNewsList,
    getNewsReadNews,
    getUserPageList,
    getReadAlert
} from '@/api/message'

const isOperator = ref(getIsoperator.userInfo.isOperator)
const btnStyle = ref(true)
const isShowModel = ref(true)
let currentKey = ref(0)
let currentListKey = ref(-1)
const hoveredIndex = ref(null);
const currentImages = ref([]);
const dialogVisibleImage = ref(false);
const tabList = ref([
    {
        tabName: '告警通知',
        img: require('../../assets/message/safe.svg'),
        unReadCounts: null
    },
    // {
    //     tabName: '待办事项',
    //     img: require('../../assets/message/subscribed.svg'),
    //     value: '5'
    // },
    {
        tabName: '平台消息',
        img: require('../../assets/message/email-mes.svg'),
        unReadCounts: null
    }
])

let warnParams = reactive({
    isRead: '',
    pageNo: 1,
    pageSize: 10,
})
let tableParams = reactive({
    stat: '',
    pageNo: 1,
    pageSize: 10,
})
const warnList = ref([])
const platformList = ref([])
const paginationObj = reactive({
    page: 1,
    listPageSize: 10,
    total: 0,
})

function handleSelView(flag) {
    btnStyle.value = !btnStyle.value
    isShowModel.value = btnStyle.value
    warnParams.pageNo = 1
    tableParams.pageNo = 1
    warnList.value = []
    platformList.value = []

    if (currentKey.value == 0) {//全部
        if (flag == 0) {
            warnParams.isRead = ''
            getUserPageListFn()
        } else {
            warnParams.isRead = 0
            getUserPageListFn()
        }
    } else {
        if (flag == 0) {
            tableParams.stat = ''
            getUserNewsListFn()
        } else {
            tableParams.stat = 0
            getUserNewsListFn()
        }
    }
}

function handleChangeTab(index) {
    currentKey.value = index
    warnParams.pageNo = 1
    tableParams.pageNo = 1
    tableParams.stat = ''
    warnParams.isRead = ''
    btnStyle.value = true
    index == 0 ? getUserPageListFn() : getUserNewsListFn()
}

function handleSizeChange(val) {
    paginationObj.listPageSize = val;
    warnParams.pageSize = val
    tableParams.pageSize = val
}

function handleCurrentChange(val) {
    paginationObj.pageNo = val;
    paginationObj.page = val;
    warnParams.pageNo = val
    tableParams.pageNo = val
    currentKey.value == 0 ? getUserPageListFn() : getUserNewsListFn()
}

function getUserPageListFn() {
    getUserPageList(warnParams).then((res) => {
        if (res.data && res.data.pageList.records.length > 0) {
            warnList.value = [...res.data.pageList.records];
        }

        tabList.value[0].unReadCounts = res.data.unReadCounts
        paginationObj.total = res.data.pageList.total
    });
}
getUserPageListFn()

function getUserNewsListFn() {
    getUserNewsList(tableParams).then((res) => {
        if (res.data && res.data.page.records.length > 0) {
            res.data.page.records.forEach(item => {
                item.compiledHtml = item.content
                item.imgs = item.imgs
            });
            platformList.value = [...res.data.page.records];
        }

        tabList.value[1].unReadCounts = res.data.unReadCounts
        paginationObj.total = res.data.page.total
    });
}

//平台账号
isOperator.value == 0 ? tabList.value.pop() : getUserNewsListFn()

function extractImages(htmlString) { //富文本处理
    // 使用 DOM 操作提取所有图片
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const getHandleData = {}
    //图片处理
    getHandleData.images = Array.from(doc.querySelectorAll('img')).map(img => ({
        src: img.src,
        alt: img.alt,
    }));
    //文本处理
    getHandleData.compiledHtml = doc.body.innerHTML.replace(/<[^>]+>/g, '')
    return getHandleData
}

function handleNewsReadNews(item) {
    console.log(item,8889);
    
    if (currentKey.value == 0) {
        getReadAlert({ userAlertIds: item.eventId.toString().split(',') })
            .then((res) => {
                if (res.message.code == 0) {
                    Message.success('操作成功')
                    warnList.value = []
                    getUserPageListFn()
                } else {
                    Message(res.message.message)
                }
            }).catch((e) => {
                Message(e)
            });
    } else {
        getNewsReadNews({ userNewIds: item.id.toString().split(',') })
            .then((res) => {
                if (res.message.code == 0) {
                    Message.success('操作成功')
                    platformList.value = []
                    getUserNewsListFn()
                } else {
                    Message(res.message.message)
                }
            }).catch((e) => {
                Message(e)
            });
    }
}

function handleAllReady() {
    currentKey.value === 0 ? handleNewsReadNews({ eventId: 0 }) : handleNewsReadNews({ id: 0 })
}

function handlePreviewImages(obj) { //预览图片
    currentImages.value = obj; // 设置当前要显示的图片列表
    dialogVisibleImage.value = true; // 打开对话框
}
</script>

<style lang="scss" scoped>
.message-body {
    padding: 16px;

    .message-box {
        // height: 100vh;
        display: flex;
        background: var(--PC-, #FFF);

        .left-box {
            width: 180px;
            padding: 16px;
            border-right: 1px solid var(--PC--, #E5E6EB);

            ul {
                img {
                    margin-right: 8px;
                    vertical-align: middle;
                    width: 16px;
                    height: 16px;
                }

                li {
                    margin-bottom: 8px;
                    padding: 8px 12px;
                    cursor: pointer;
                    color: #4E5969;
                    font-size: 14px;
                    font-weight: 500;

                    .item {
                        margin-left: 4px;
                        margin-top: 9px;
                    }
                }


            }

            ul li:hover {
                border-radius: 4px;
                color: #057C98;
                background: rgba(5, 124, 152, 0.04);
                /* 悬停时的背景颜色 */
            }

            .activeTab {
                border-radius: 4px;
                color: #057C98;
                background: rgba(5, 124, 152, 0.04);
            }
        }

        .right-box {
            flex: 1;
            padding: 16px;

            .header-tag {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;
                padding-bottom: 12px;
                border-bottom: 1px solid var(--PC--, #E5E6EB);

                .right-flag {
                    color: var(--PC-, #057C98);
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    cursor: pointer;
                }
            }

            .message-container {
                .list {
                    margin-bottom: 16px;
                    padding: 16px 24px;
                    border-radius: 8px;
                    cursor: pointer;
                    border: 1px solid var(--PC--, #E5E6EB);

                    .item-title,
                    .item-desc {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .item-title {
                        padding-bottom: 8px;
                        border-bottom: 1px solid #F2F3F5;


                        & div:nth-child(1) {
                            display: flex;
                            align-items: center;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 24px;
                            color: var(--PC-, #1D2129);
                            font-family: "PingFang SC";

                            .is-dot {
                                margin-right: 8px;
                                width: 6px;
                                height: 6px;
                                border-radius: 50%;
                                // background-color: #DF3737;
                            }

                            .is-state1,
                            .is-state2,
                            .is-state3 {
                                margin-left: 8px;
                                padding: 0 4px;
                                border-radius: 4px;
                                font-family: "PingFang SC";
                                font-size: 12px;
                                font-weight: 400;
                            }

                            .is-state1 {
                                border: 1px solid #DF3737;
                                color: #DF3737;
                            }

                            .is-state2 {
                                border: 1px solid #F87700;
                                color: #F87700;
                            }

                            .is-state3 {
                                border: 1px solid #057C98;
                                color: #057C98;
                            }
                        }

                        & div:nth-child(2) {
                            & div:nth-child(1) {
                                color: var(--PC-, #86909C);
                                font-family: "PingFang SC";
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 22px;
                            }
                        }
                    }

                    .item-desc {
                        padding-top: 8px;

                        & div:nth-child(1),
                        & div:nth-child(2),
                        & div:nth-child(3) {
                            display: flex;
                            align-items: center;

                            & div:nth-child(1) {
                                color: var(--PC-, #86909C);
                                font-family: "PingFang SC";
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 22px;
                            }

                            & div:nth-child(2) {
                                overflow: hidden;
                                color: var(--PC-, #1D2129);
                                font-family: "PingFang SC";
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 22px;
                            }
                        }
                    }

                }

                .list:hover {
                    background: rgba(29, 33, 41, 0.04);
                    ;
                }

                .activeList {
                    filter: opacity(0.6);
                }
            }

            .platform-container {
                .list {
                    margin-bottom: 16px;
                    padding: 16px 24px;
                    border-radius: 8px;
                    cursor: pointer;
                    border: 1px solid var(--PC--, #E5E6EB);

                    .item-title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .item-title {
                        padding-bottom: 8px;
                        border-bottom: 1px solid #F2F3F5;


                        & div:nth-child(1) {
                            display: flex;
                            align-items: center;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 24px;
                            color: var(--PC-, #1D2129);
                            font-family: "PingFang SC";

                            .is-dot {
                                margin-right: 8px;
                                width: 6px;
                                height: 6px;
                                border-radius: 50%;
                                background-color: #DF3737;
                            }

                        }

                        & div:nth-child(2) {
                            & div:nth-child(1) {
                                color: var(--PC-, #86909C);
                                font-family: "PingFang SC";
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 22px;
                            }
                        }
                    }

                    .item-desc {
                        padding-top: 8px;
                        color: var(--PC-, #86909C);
                        font-family: "PingFang SC";
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22px;
                    }
                }

                .list:hover {
                    background: rgba(29, 33, 41, 0.04);
                }

                .ready-stat {
                    padding: 2px 8px;
                    border: 1px solid red;
                    border-radius: 4px;
                    border: 1px solid #E5E6EB;
                    color: #1D2129;
                    background: #FFF;
                }

                .ready-time {
                    padding: 2px 8px;
                    border: 1px solid transparent;
                }
            }
        }
    }
}

.empty-block {
    display: flex;
    // transform: translate(0, 100%);
    height: 200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 8px;
    margin-top: 16px;
}
.image-swiper {
        width: 400px;
        height: 300px;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
</style>